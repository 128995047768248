<!--
  创建需要的表单
 -->
<template>
  <div class="main_content">
    <el-form
      ref="elForm"
      :model="$_formData"
      :rules="rules"
      size="small"
      label-width="180px"
      label-position="left">
      <el-form-item
        label="需求名称"
        prop="title">
        <el-input maxlength="50" show-word-limit
          style="width:100%;"
          v-model="$_formData.title"
          placeholder="请输入需求名称"
          clearable>
        </el-input>
      </el-form-item>
      <!-- 选择一级的服务类型 -->
      <el-form-item
        label="需求类别"
        prop="serviceTypeId">
        <el-select
          v-model="$_formData.serviceTypeId"
          placeholder="请选择需求类别"
          clearable
          style="width: 100%">
          <el-option
            v-for="item in serviceTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="行业领域"
        prop="industryId">
        <el-select
          style="width:100%;"
          v-model="$_formData.industryId"
          multiple
          placeholder="请选择行业领域">
          <el-option
            v-for="item in industrySectorList"
            :key="item.id"
            :value="item.id"
            :label="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="技术领域"
        prop="technicalId">
        <el-select
          style="width:100%;"
          v-model="$_formData.technicalId"
          multiple
          placeholder="请选择技术领域">
          <el-option
            v-for="item in technicalList"
            :key="item.id"
            :value="item.id"
            :label="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="时限要求"
        prop="startAt">
        <el-date-picker
          type="daterange"
          v-model="begin_end_date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :style="{width: '100%'}"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          range-separator="~"
          clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="需求描述"
        prop="description">
        <el-input
          style="width: 100%;"
          type="textarea" maxlength="300" show-word-limit
          :rows="4"
          placeholder="需求背景概述、需解决的主要问题、拟实现的主要目标"
          v-model="$_formData.description">
        </el-input>
      </el-form-item>
      <el-form-item
        label="关键词"
        prop="keywords">
        <el-input maxlength="50" show-word-limit
          style="width:100%;"
          v-model="$_formData.keywords"
          placeholder="请输入需求关键词"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        label="预算金额(元)"
        prop="budget">
        <el-input maxlength="50" show-word-limit
          style="width:100%;"
          v-model="$_formData.budget"
          placeholder="请输入预算金额"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        label="需求所属企业名称"
        prop="enterpriseName">
        <el-input maxlength="50" show-word-limit
          style="width:100%;"
          v-model="$_formData.enterpriseName"
          placeholder="请输入需求所属企业名称"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        label="统一社会信用代码"
        prop="socialCreditCode">
        <el-input maxlength="50" show-word-limit
          style="width:100%;"
          v-model="$_formData.socialCreditCode"
          placeholder="请输入统一社会信用代码"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        label="企业所在区域"
        prop="province">
        <el-select
          size="small"
          style="width:100%;"
          v-model="$_formData.province"
          placeholder="请选择企业所在区域"
          clearable
          @change="onSearch">
          <el-option
            v-for="item in staticData.provinceList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="联系人"
        prop="contacts">
        <el-input maxlength="50" show-word-limit
          style="width: 100%;"
          v-model="$_formData.contacts"
          placeholder="请输入联系人">
        </el-input>
      </el-form-item>
      <el-form-item
        label="联系电话"
        prop="contactMobile">
        <el-input maxlength="11" show-word-limit
          style="width: 100%;"
          v-model="$_formData.contactMobile"
          placeholder="请输入联系电话">
        </el-input>
      </el-form-item>
      <el-form-item
        label="联系邮箱"
        prop="contactEmail">
        <el-input maxlength="50" show-word-limit
          style="width: 100%;"
          v-model="$_formData.contactEmail"
          placeholder="请输入联系邮箱">
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import * as api from '@/api'
export default {
  data () {
    return {
      rules: {
        title: [
          { required: true, message: '请输入需求标题', trigger: 'blur' }
        ],
        serviceTypeId: [
          { required: true, message: '请选择需求类别', trigger: 'change' }
        ],
        industryId: [
          { required: true, message: '请选择行业领域', trigger: 'change' }
        ],
        technicalId: [
          { required: true, message: '请选择目标技术领域', trigger: 'change' }
        ],
        startAt: [
          { required: true, message: '时限要求不能为空', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入需求描述', trigger: 'blur' }
        ],
        keywords: [
          { required: true, message: '请输入需求关键词', trigger: 'change' }
        ],
        budget: [
          { required: true, message: '请输入预算金额', trigger: 'change' }
        ],
        enterpriseName: [
          { required: true, message: '请输入需求所属企业名称', trigger: 'blur' }
        ],
        socialCreditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '请输入企业所在区域', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        contactMobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确邮箱格式', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 需求表单
    requireForm: {
      type: Object,
      default: () => ({
        title: '', // 需求标题
        serviceTypeId: '', // 需求类型（一级服务id）
        industryId: [], // 行业领域
        technicalId: [], // 技术领域
        description: '', // 需求描述
        keywords: '', // 关键字
        budget: '', // 预算金额
        enterpriseName: '', // 企业名称
        socialCreditCode: '', // 社会信用代码
        province: '', // 所在区域
        contacts: '', // 联系人
        contactMobile: '', // 联系电话
        contactEmail: '', // 联系邮箱
        startAt: '', // 有效期开始
        endAt: '' // 有效期结束
        // title: '需求名称',
        // serviceTypeId: '097bcb6e056b65e386f5003fdbf0f1a9',
        // industryId: [
        //   '1d94a23441dbdd1543558e6d62a509a9',
        //   '258c124d6dcf23f395ca3b25a601e12b',
        //   '27d951e09829dcd4b68febfdc1a290da'
        // ],
        // technicalId: [
        //   '5e79b1151fb70242bd5d3f85b3ef1f90',
        //   '29056a0eee037b4dd9b1c59b1bab8aff',
        //   '6254a2cf3348ff21ad6c86b6d67471db',
        //   '66f8ee9b8d7b87ccc2f52378803239d2'
        // ],
        // description: '3.1的新需求',
        // keywords: '关键字1,，关键字2',
        // budget: '220001',
        // enterpriseName: '优聘',
        // enterpriseScale: '中型',
        // socialCreditCode: '423254541',
        // province: '湖北武汉',
        // enterpriseNature: '国有',
        // contacts: '赵思',
        // contactMobile: '17371278540',
        // contactEmail: '1159902844@qq.com',
        // startAt: '',
        // endAt: ''
      })
    }
  },
  computed: {
    // 表单
    $_formData: {
      get () {
        return this.requireForm
      },
      set (value) {
        this.$emit('update:requireForm', value)
      }
    },
    // 日期范围
    begin_end_date: {
      get () {
        return [new Date(this.$_formData.startAt), new Date(this.$_formData.endAt)]
      },
      set (newValue) {
        if (newValue) {
          this.$_formData.startAt = newValue[0]
          this.$_formData.endAt = newValue[1]
        } else {
          this.$_formData.startAt = ''
          this.$_formData.endAt = ''
        }
      }
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 一级服务类型
    serviceTypeOptions () {
      return this.$store.state.serviceTypeLevel1
    }
  },
  methods: {
    // 对表单进行验证并取值
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.elForm.validate()
          .then(res => {
            resolve(this.$_formData)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    // 重置表单
    resetForm () {
      this.$refs.elForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.main_content {
  width: 560px;
  // margin: 0 auto;
}
</style>
